import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { LikedDance } from './models';
import { getCurrentUser } from '@aws-amplify/auth';
import { FaHeart } from 'react-icons/fa';
import './LikedDancesComponent.css';

function LikedDancesComponent() {
  const [likedDances, setLikedDances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [filteredDances, setFilteredDances] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  const shuffleArray = (array) => {
    // Create a copy of the array to avoid mutating the original
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      // Generate random index
      const j = Math.floor(Math.random() * (i + 1));
      // Swap elements
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const openSingleTrackInSpotify = (dance) => {
    if (!dance.songId) {
      alert('No Spotify track available for this dance');
      return;
    }
  
    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
        window.location.href = `https://open.spotify.com/track/${dance.songId}`;
      } else {
        window.location.href = `spotify:track:${dance.songId}`;
      }
    } catch (error) {
      console.error('Error opening Spotify:', error);
      alert('Unable to open Spotify. Please make sure Spotify is installed.');
    }
  };
  

  const openInSpotify = (dances) => {
    const validTracks = dances.filter(dance => dance.songId);
    
    if (validTracks.length === 0) {
      alert('No Spotify tracks available for the selected dances');
      return;
    }
  
    try { 
      if (validTracks.length === 1) {
          window.location.href = `spotify:track:${validTracks[0].songId}`;
    } else {
      // For multiple tracks
      const shuffledTracks = shuffleArray(validTracks);
      
      // For desktop, try the URI scheme
      const trackUris = shuffledTracks
        .map(dance => `spotify:track:${dance.songId}`)
        .join(':');
      window.location.href = `spotify:trackset:DanceScout:${trackUris}`;
    }
    } catch (error) {
      console.error('Error opening Spotify:', error);
      alert('Unable to open Spotify. Please make sure Spotify is installed.');
    }
  };

  useEffect(() => {
    
    // Subscribe to changes
    let subscription;
    const setupSubscription = async () => {
      try {
        const user = await getCurrentUser();
        //const userEmail = user.signInDetails.loginId;
        subscription = DataStore.observe(LikedDance, 
          dance => dance.userEmail.eq(user.userId)
        ).subscribe({
          next: ({ opType, element }) => {
              if (opType === 'INSERT') {
                setLikedDances(prev => [element, ...prev]);
              } else if (opType === 'DELETE') {
                setLikedDances(prev => prev.filter(d => d.id !== element.id));
              }
            }
        });
      } catch (error) {
        console.error('Error setting up subscription:', error);
      }
    };

    fetchLikedDances();
    setupSubscription();

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [retryCount]); // Add retryCount as dependency

  // Add new useEffect to handle filtering
  useEffect(() => {
    if (selectedStyles.length === 0) {
      setFilteredDances(likedDances);
    } else {
      const filtered = likedDances.filter(dance => 
        selectedStyles.includes(dance.danceStyle)
      );
      setFilteredDances(filtered);
    }
  }, [selectedStyles, likedDances]);

  // Get unique dance styles from liked dances
  const getDanceStyles = () => {
    const styles = [...new Set(likedDances.map(dance => dance.danceStyle))];
    return styles;
  };

  const handleStyleSelect = (style) => {
    setSelectedStyles(prev => {
      if (prev.includes(style)) {
        return prev.filter(s => s !== style);
      }
      return [...prev, style];
    });
  };

// Define fetchLikedDances outside useEffect
const fetchLikedDances = async () => {
  try {
    const user = await getCurrentUser();
    // Use loginId instead of username
    const userEmail = user.signInDetails.loginId;
    //console.log('Using email:', userEmail); // Debug log
    // Wait for DataStore to be ready
    await DataStore.start();
    
    const fetchedLikedDances = await DataStore.query(LikedDance, 
      c => c.userEmail.eq(userEmail
      )
    );
    
    //console.log('Fetched dances:', fetchedLikedDances); // Debug log
    
    // Sort by most recently added
    const sortedDances = fetchedLikedDances.sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    
    setLikedDances(sortedDances);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching liked dances:', error);
    
    // Retry logic
    if (retryCount < 3) {
      setTimeout(() => {
        setRetryCount(prev => prev + 1);
      }, 1000);
    } else {
      setLoading(false);
    }
  }
};

  const handleUnlike = async (dance) => {
    try {
      await DataStore.delete(dance);
      setLikedDances(prev => prev.filter(d => d.id !== dance.id));
    } catch (error) {
      console.error('Error removing like:', error);
    }
  };

  // Add this function to check if we should show the Spotify button
  const shouldShowSpotifyButton = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return !isMobile;
  };

  if (loading) {
    return <div className="loading">Loading your liked dances...</div>;
  }

  return (
    <div className="liked-dances-container">
      <h1 className='title'>Your Liked Dances</h1>
      
      {/* Add the multi-select filter */}
      <div className="dance-style-filter">
        {getDanceStyles().map(style => (
          <button
            key={style}
            className={`filter-button ${selectedStyles.includes(style) ? 'active' : ''}`}
            onClick={() => handleStyleSelect(style)}
          >
            {style}
          </button>
        ))}
      </div>
      {filteredDances.length > 0 && shouldShowSpotifyButton() && (
        <button 
          className="spotify-button-all"
          onClick={() => openInSpotify(filteredDances)}
        >
          <span role="img" aria-label="music">🎵</span> 
          Open {filteredDances.length} {filteredDances.length === 1 ? 'Song' : 'Songs'} in
          <img 
            src="/spotify/Spotify_Full_Logo_RGB_Black.png"
            alt="Spotify"
            className="spotify-logo-all"
          />
        </button>
      )}

      {filteredDances.length === 0 ? (
        <p>No dances match your selected filters!</p>
      ) : (
        <div className="liked-dances-list">
          {filteredDances.map((dance) => (
            <div key={dance.id} className="liked-dance-item">
              <div className="dance-info">
                <h2>{dance.title}</h2>
                <h3>Artist: {dance.artist}</h3>
                <h3>Dance: {dance.danceStyle}</h3>
                {dance.songId && (
                <button 
                  className="spotify-button-small"
                  onClick={() => openInSpotify(dance.spotifyId)} // Use your existing openSpotify function
                  aria-label={`Play ${dance.title} on Spotify`}
                >
              <img 
                src="/spotify/Spotify_Full_Logo_RGB_Black.png"
                alt="Play on Spotify"
              />
              </button>
              )}
              </div>
              <button 
                onClick={() => handleUnlike(dance)}
                className="unlike-button"
                aria-label="Unlike dance"
              >
                <FaHeart />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LikedDancesComponent;
