import React, { useState, useEffect } from 'react';
import './App.css';
import { FiLogOut } from 'react-icons/fi';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import SearchComponent from './SearchComponent';
import LikedDancesComponent from './LikedDancesComponent';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { DataStore } from 'aws-amplify/datastore';
import Footer from './basics/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Impressum from './basics/Impressum';
import Datenschutz from './basics/Datenschutz';


Amplify.configure(awsconfig);

function App() {
  const [activeTab, setActiveTab] = useState('search');
  const [isDataStoreReady, setIsDataStoreReady] = useState(false);

  useEffect(() => {
    let subscription;

    const initDataStore = async () => {
      try {
      await DataStore.stop();
      await DataStore.clear();

      // Configure DataStore
      DataStore.configure({
        errorHandler: (error) => {
          console.error('DataStore error:', error);
        }
      });
      
      // Start DataStore with logging
      await DataStore.start();
      
        setIsDataStoreReady(true);

        // Subscribe to DataStore sync status
        /*subscription = DataStore.observe().subscribe({
          next: (data) => {
            console.log('DataStore sync update:', data);
          },
          error: (error) => {
            console.error('DataStore sync error:', error);
            setIsDataStoreReady(false);
          }
        });*/

      } catch (error) {
        console.error('Error initializing DataStore:', error);
        setIsDataStoreReady(false);
      }
    };

    // Initial setup
    initDataStore();

    return () => {
      // Cleanup
      if (subscription) {
        subscription.unsubscribe();
      }
      
      // Stop DataStore when component unmounts
      DataStore.stop();
    };
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <div className="App">
      <Router>
      <Authenticator>
        {({ signOut }) => (
          <main>
            <button 
              onClick={signOut} 
              className="sign-out-button"
              aria-label="Sign out"
            >
              <FiLogOut />
            </button>
            <Routes>
                <Route path="/" element={
                  <div className="content-wrapper">
              {!isDataStoreReady && (
                <div>Synchronizing data...</div>
              )}
              {isDataStoreReady && (
                <>
                  <div className="tabs">
                    <button 
                      className={`tab ${activeTab === 'search' ? 'active' : ''}`}
                      onClick={() => setActiveTab('search')}
                    >
                      Search Dances
                    </button>
                    <button 
                      className={`tab ${activeTab === 'liked' ? 'active' : ''}`}
                      onClick={() => setActiveTab('liked')}
                    >
                      Liked Dances
                    </button>
                  </div>

                  <div className="tab-content">
                    {activeTab === 'search' ? (
                      <SearchComponent />
                    ) : (
                      <LikedDancesComponent />
                    )}
                  </div>
                </>
              )}
            </div>
                } />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/privacy" element={<Datenschutz />} />
            </Routes>
            <Footer />
          </main>
        )}
      </Authenticator>
      </Router>
    </div>
  );
}

export default withAuthenticator(App);
