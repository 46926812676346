import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './SearchComponent.css';
import { FaHeart, FaRegHeart } from 'react-icons/fa'; // Make sure to install react-icons: npm install react-icons
import { DataStore } from '@aws-amplify/datastore';
import { LikedDance } from './models'; // This path might need to be adjusted depending on where your models are generated
import { getCurrentUser } from '@aws-amplify/auth';


function SearchComponent() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showAllResults, setShowAllResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedTrackData, setSelectedTrackData] = useState(null);
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const inputRef = useRef(null);
  const [dances, setDances] = useState([]);
  const [likedDances, setLikedDances] = useState({});

  const sortDances = (dances, trackTact, trackBpm) => {
    return dances.sort((a, b) => {
      // First, sort by matching tact
      if (a.tact === trackTact && b.tact !== trackTact) return -1;
      if (b.tact === trackTact && a.tact !== trackTact) return 1;
      
      // If tacts are the same, sort by BPM closeness
      const aBpmDiff = Math.abs(a.bpm - trackBpm);
      const bBpmDiff = Math.abs(b.bpm - trackBpm);
      return aBpmDiff - bBpmDiff;
    });
  };

  useEffect(() => {
    const fetchUserAndLikedDances = async () => {
      try {
        const user = await getCurrentUser();
        const userEmail = user.signInDetails.loginId;
  
        const fetchedLikedDances = await DataStore.query(LikedDance, 
          c => c.userEmail.eq(userEmail)
        );
        
        const likedDancesMap = fetchedLikedDances.reduce((acc, dance) => {
          acc[`${dance.songId}-${dance.danceStyle}`] = true;
          return acc;
        }, {});
        
        setLikedDances(likedDancesMap);
      } catch (error) {
        console.error('Error fetching user or liked dances:', error);
      }
    };
  
    fetchUserAndLikedDances();
    fetch('/dances.json')
      .then(response => response.json())
      .then(data => setDances(data))
      .catch(error => console.error('Error fetching dances:', error));

    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        performSearch();
      } else {
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
  }, [likedDances]);

  const handleLike = async (song, dance) => {
    try {
      const user = await getCurrentUser();
      const userEmail = user.signInDetails.loginId;
      
      const danceId = `${song.id}-${dance.name}`;
      console.log('Attempting to like/unlike dance:', danceId);
  
      // Check if already liked
      const existingLikes = await DataStore.query(LikedDance, (c) => 
        c.and(c => [
          c.userEmail.eq(userEmail),
          c.songId.eq(song.id),
          c.danceStyle.eq(dance.name)
        ])
      );
  
  
      if (existingLikes.length > 0) {
        await DataStore.delete(existingLikes[0]);
      } else {
        await DataStore.save(
          new LikedDance({
            userEmail: userEmail, // Use the correct email
            songId: song.id,
            danceStyle: dance.name,
            title: song.title,
            artist: song.artist,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          })
        );
      }
  
      // Update UI state
    setLikedDances(prev => ({
      ...prev,
      [danceId]: !prev[danceId]
    }));
  
    } catch (error) {
      console.error('Error handling like:', error);
      // Log detailed error information
      if (error.message) console.error('Error message:', error.message);
      if (error.code) console.error('Error code:', error.code);
    }
  };
  
  const openSingleTrackInSpotify = (track) => {
    if (!track.id) {
      alert('No Spotify track available for this song');
      return;
    }
  
    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
        window.location.href = `https://open.spotify.com/track/${track.id}`;
      } else {
        window.location.href = `spotify:track:${track.id}`;
      }
    } catch (error) {
      console.error('Error opening Spotify:', error);
      alert('Unable to open Spotify. Please make sure Spotify is installed.');
    }
  };


  const performSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://cw69e7ta99.execute-api.eu-central-1.amazonaws.com/Dev/track?search=${searchTerm}`);
      setSearchResults(response.data);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Add a function to handle new searches
const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
  setShowSuggestions(true);
  // Reset states when starting a new search
  setSelectedTrackData(null);
  setShowAllResults(false);
};

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsLoading(true);
      setShowSuggestions(false);
      // Reset previous results and details
      setSelectedTrackData(null);
      setSearchResults([]);
      setShowAllResults(true);
      
      try {
        const response = await axios.get(`https://cw69e7ta99.execute-api.eu-central-1.amazonaws.com/Dev/track?search=${searchTerm}`);
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSuggestionClick = async (result) => {
    setShowSuggestions(false); // Close the search results
    setIsLoading(true);
    setSelectedSearchResult(result);

    try {
      const response = await axios.get(`https://cw69e7ta99.execute-api.eu-central-1.amazonaws.com/Dev/track/analyze/?id=${result.id}`);
      setSelectedTrackData(response.data);
      setShowSuggestions(false);
    } catch (error) {
      console.error('Error fetching track details:', error);
      setSelectedTrackData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  // Helper function to format percentage
  const formatPercentage = (value) => {
    return (value * 100).toFixed(2) + '%';
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-container" ref={inputRef}>
      <h1 className="title">Dance Scout</h1>
      <input
        type="text"
        className="search-input"
        placeholder="Search for tracks..."
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
      />
      {isLoading && <div className="loading">Loading...</div>}
      {showSuggestions && searchResults.length > 0 && (
        <ul className="suggestions-list">
          {searchResults.map((result) => (
            <li 
              key={result.id} 
              onClick={() => handleSuggestionClick(result)}
              className="suggestion-item"
            >
              <span className="suggestion-title">{result.title} - </span>
              <span className="suggestion-artist">{result.artist}</span>
            </li>
          ))}
        </ul>
      )}
   {showAllResults && searchResults.length > 0 && !selectedTrackData && (
      <div className="all-search-results">
    <h2>Search Results</h2>
    {searchResults.map((result) => (
      <div key={result.id} className="search-result-item">
        <div className="result-info">
          <h3>{result.title}</h3>
          <p>Artist: {result.artist}</p>
          <p>Album: {result.album}</p>
        </div>
        <div className="result-actions">
          <button 
            onClick={() => handleSuggestionClick(result)}
            className="view-details-button"
            aria-label={`View details for ${result.title}`}
          >
            <span role="img" aria-label="info">ℹ️</span>Details
          </button>
          {result.id && (
            <button 
              className="spotify-button-small"
              onClick={() => openSingleTrackInSpotify(result)}
              aria-label={`Play ${result.title} on Spotify`}
            >
          <img 
            src="/spotify/Spotify_Full_Logo_RGB_Black.png"
            alt="Play on Spotify"
          />
          </button>
        )}
        </div>
      </div>
    ))}
  </div>
)}

      {selectedTrackData && selectedSearchResult && (
        <div className="selected-track-details">
        <button 
          className="back-button"
          onClick={() => {
            setSelectedTrackData(null);
            setShowAllResults(true);
          }}
        >
          ← Back to results
        </button>
        <h3>{selectedSearchResult.title}</h3>
        <div className="track-info">
          <p><strong>Artist:</strong> {selectedSearchResult.artist}</p>
          <p><strong>Album:</strong> {selectedSearchResult.album}</p>
        </div>
        <div className="track-analysis">
          <div className="analysis-item">
            <span className="tooltip-container"
            role="button"
            tabIndex="0"
            aria-label="BPM information">BPM:
            <div className="tooltip" role="tooltip">
            Beats Per Minute - The tempo of the track. This indicates how fast or slow the music is.
          </div></span>
            <span>{selectedTrackData.bpm} (Confidence: {formatPercentage(selectedTrackData.bpm_confidence)})</span>
          </div>
          <div className="analysis-item">
            <span className="tooltip-container"
            role="button"
            tabIndex="0"
            aria-label="Tact information">Takt:
            <div className="tooltip" role="tooltip">
            Time signature of the track (e.g., 4/4, 3/4). This indicates how the beats are grouped in the music.
          </div></span>
            <span>{selectedTrackData.tact}/4 (Confidence: {formatPercentage(selectedTrackData.tact_confidence)})</span>
          </div>
          <div className="analysis-item">
            <span className="tooltip-container"
            role="button"
            tabIndex="0"
            aria-label="Danceability information">Danceability:
            <div className="tooltip" role="tooltip">
            A measure from 0% to 100% indicating how suitable the track is for dancing based on tempo, rhythm stability, and beat strength.
          </div></span>
            <span>{formatPercentage(selectedTrackData.danceability)}</span>
          </div>
          <div className="analysis-item">
            <span className="tooltip-container"
            role="button"
            tabIndex="0"
            aria-label="Valence information">Valence:
            <div className="tooltip" role="tooltip">
            A measure from 0% to 100% describing the musical positiveness conveyed by a track. High valence sounds more positive (happy, cheerful), while low valence sounds more negative (sad, angry).
          </div></span>
            <span>{formatPercentage(selectedTrackData.valence)}</span>
          </div>
        </div>
        
        <div className="dances-list">
            <h4>Dances</h4>
            <ul>
            {sortDances(dances, selectedTrackData.tact, selectedTrackData.bpm).map((dance, index) => (
               <li key={dance.name} className="dance-item">
                  <span>{dance.name} ~{dance.bpm}BPM</span>
                  <button 
                    onClick={() => handleLike(selectedSearchResult, dance)}
                    className="like-button"
                    >
                    {likedDances[`${selectedSearchResult.id}-${dance.name}`] ? <FaHeart color="red" /> : <FaRegHeart />}
                  </button>
                </li>
              ))}
            </ul>
          </div>
      </div>
      )}
    </div>
  );
}

export default SearchComponent;
