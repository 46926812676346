import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer" role="contentinfo">
      <div className="footer-content">
        <nav className="footer-links" aria-label="Footer navigation">
          <a href="/impressum">Impressum</a>
          <a href="/privacy">Datenschutz</a>
          <span className="copyright">© {currentYear} Kay-Max Ströhlein</span>
        </nav>
        <div className="spotify-logo">
          <img 
            src="/spotify/Spotify_Full_Logo_RGB_Green.png" 
            alt="Powered by Spotify" 
            height="18"
            loading="lazy"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
