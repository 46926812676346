import React from 'react';
import './Impressum.css';

const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1>Impressum</h1>
      
      <section className="impressum-section">
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>Kay-Max Ströhlein</p>
        <p>Callenberger Straße 6</p>
        <p>96450 Coburg</p>
      </section>

      <section className="impressum-section">
        <h2>Kontakt</h2>
        <p>E-Mail: info@stroehlein.biz</p>
      </section>

      <section className="impressum-section">
        <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
        <p>Kay-Max Ströhlein</p>
        <p>Callenberger Straße 6</p>
        <p>96450 Coburg</p>
      </section>

      <section className="impressum-section">
        <h2>Haftungsausschluss</h2>
        <h3>Haftung für Inhalte</h3>
        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, 
        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>
        
        <h3>Haftung für Links</h3>
        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen 
        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.</p>
      </section>
    </div>
  );
};

export default Impressum;
