import React from 'react';
import './Datenschutz.css';

const Datenschutz = () => {
  return (
    <div className="datenschutz-container">
      <h1>Datenschutzerklärung</h1>

      <section className="datenschutz-section">
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, 
        wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.</p>
      </section>

      <section className="datenschutz-section">
        <h2>2. Verantwortlicher</h2>
        <p>Verantwortlich für die Datenverarbeitung auf dieser Website ist:</p>
        <p>Kay-Max Ströhlein</p>
        <p>Callenberger Straße 6</p>
        <p>96450 Coburg</p>
        <p>E-Mail: info@stroehlein.biz</p>
      </section>

      <section className="datenschutz-section">
        <h2>3. Datenerfassung auf unserer Website</h2>
        
        <h3>Cookies</h3>
        <p>Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. 
        Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.</p>

        <h3>Server-Log-Dateien</h3>
        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, 
        die Ihr Browser automatisch an uns übermittelt.</p>
      </section>

      <section className="datenschutz-section">
        <h2>4. AWS Amplify und Amazon Web Services</h2>
        <p>Diese Website nutzt AWS Amplify, einen Dienst von Amazon Web Services (AWS). 
        Die Datenverarbeitung findet in der EU statt. Weitere Informationen finden Sie in der 
        AWS-Datenschutzerklärung.</p>
      </section>

      <section className="datenschutz-section">
        <h2>5. Spotify Integration</h2>
        <p>Unsere Website verwendet die Spotify API zur Integration von Musikinhalten. 
        Wenn Sie diese Funktionen nutzen, werden bestimmte Daten an Spotify übermittelt. 
        Weitere Informationen finden Sie in der Datenschutzerklärung von Spotify.</p>
      </section>

      <section className="datenschutz-section">
        <h2>6. Ihre Rechte</h2>
        <p>Sie haben jederzeit das Recht:</p>
        <ul>
          <li>Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten</li>
          <li>Die Berichtigung oder Löschung Ihrer personenbezogenen Daten zu verlangen</li>
          <li>Eine Einschränkung der Verarbeitung zu verlangen</li>
          <li>Der Verarbeitung zu widersprechen</li>
          <li>Ihre Daten in einem strukturierten Format zu erhalten</li>
        </ul>
      </section>
    </div>
  );
};

export default Datenschutz;
